$primary: #031D44;
$secondary: #C8F64A;


    .wild-header-styles {
        @apply font-medium text-gray-900;
    }

    .wild-header-large {
        @apply text-2xl;
        @apply font-extrabold;
        @apply tracking-tight;
        @apply text-gray-900;
    }

    .wild-accent-colour{
        @apply text-emerald-600;
      }

    .wild-header {
        @apply wild-header-styles;
        @apply text-lg;
    }

    .wild-header-2 {
        @apply wild-header-styles;
        @apply text-sm;
    }

    .wild-header-small {
        @apply text-sm;
        @apply font-medium;
        @apply text-gray-500;
    }

    .wild-paragraph {
        @apply text-sm;
        @apply text-gray-500;
    }

    .wild-paragraph-prominent {
        @apply mt-1;
        @apply text-sm;
        @apply text-gray-900;
        @apply space-y-5;
        @apply whitespace-pre-line;
    }

    .wild-paragraph-small {
        @apply text-xs;
        @apply text-gray-500;
    }

    .wild-text-primary {
        color: $primary;
    }

    .wild-text-error {
        @apply text-red-500;
    }

    .wild-bg-primary {
        background: $primary;
    }

    .wild-text-secondary {
        color: $secondary;
    }

    .wild-badge {
        @apply mr-2;
        @apply inline-flex;
        @apply items-center;
        @apply px-2;
        @apply py-0.5;
        @apply rounded;
        @apply text-xs;
        @apply font-medium;
        @apply mb-2;
    }

    .wild-badge-light {
        @apply wild-badge;
        @apply bg-white;
        @apply border;
        @apply text-blue-400;
        @apply border-gray-300;
    }

    .wild-badge-primary {
        @apply wild-badge;
        @apply bg-blue-100;
        @apply text-blue-800;
    }

    .wild-bg-secondary {
        background: $secondary;
    }

    .wild-btn {
        @apply cursor-pointer;
        @apply inline-flex;
        @apply justify-center;
        @apply py-2;
        @apply px-4;
        @apply border;
        @apply border-transparent;
        @apply shadow-sm;
        @apply text-sm;
        @apply font-medium;
        @apply rounded-md;
        @apply focus:outline-none;
        @apply focus:ring-2; 
        @apply focus:ring-offset-2;
    }

    .wild-btn-primary {
        @apply wild-btn;
        @apply wild-bg-primary;
        @apply text-white;
        @apply hover:bg-blue-900;
    }

    .wild-btn-error {
        @apply wild-btn;
        @apply bg-red-600;
        @apply text-white;
        @apply hover:bg-red-700;
    }

    .wild-btn-warning {
        @apply wild-btn;
        @apply bg-yellow-600;
        @apply text-white;
        @apply hover:bg-yellow-700;
    }

    .wild-btn-light {
        @apply wild-btn;
        @apply bg-white;
        @apply border-gray-300;
        @apply text-gray-500;
        @apply hover:bg-gray-50;
    }

    .wild-btn-icon {
        @apply inline-flex;
        @apply items-center;
        @apply p-2;
        @apply border;
        @apply text-center;
        @apply border-transparent;
        @apply rounded-full;
        @apply shadow-sm;
        @apply focus:outline-none;
        @apply focus:ring-2;
        @apply focus:ring-offset-2;
    }

    .wild-btn-icon-sm {
        @apply wild-btn-icon;
        @apply p-1;
        @apply rounded-full;
        @apply shadow-sm;
        @apply focus:ring-1;
        @apply focus:ring-offset-1;
    }

    .wild-btn-icon-primary {
        @apply wild-btn-icon;
        @apply text-white;
        @apply wild-bg-primary;
        @apply hover:bg-blue-600;
        @apply focus:ring-blue-500;
    }

    .wild-btn-icon-primary-sm {
        @apply wild-btn-icon-sm;
        @apply text-white;
        @apply wild-bg-primary;
        @apply hover:bg-blue-600;
        @apply focus:ring-blue-500;
    }

    .wild-btn-icon-light {
        @apply wild-btn-icon;
        @apply bg-white;
        @apply wild-text-primary;
        @apply shadow-lg;
        @apply border-2;

        @apply hover:bg-gray-100;
        @apply focus:ring-gray-100;
    }

    .wild-btn-icon-light-sm {
        @apply wild-btn-icon-sm;
        @apply bg-white;
        @apply wild-text-primary;
        @apply shadow-sm;
        @apply border-2;
        @apply border-blue-600;
        @apply hover:bg-gray-100;
        @apply focus:ring-gray-100;
    }

    .wild-link {
        @apply cursor-pointer;
        @apply font-medium;
        @apply wild-text-primary;
        @apply hover:text-blue-900;
    }

    .wild-label {
        @apply text-xs;
        @apply font-semibold;
        @apply text-gray-700;
    }
