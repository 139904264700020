@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "../node_modules/date-of-birth-js/dist/css/date-of-birth-js.min.css";
@tailwind base; 
@tailwind components;  
// IMPORTANT THIS IS BEFORE UTILITIES
@import "./custom-tailwind/custom-components.scss";
@tailwind utilities; 
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

html { 
  font-family: 'Poppins', sans-serif;
}
.datepicker-container { 
  
}
.loader {
  border: 2px solid #75a8f3; 
  border-radius: 50%;
  border-top: 2px solid #ffffff;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 1.3s linear infinite;
  /* Safari */
  animation: spin 1.3s linear infinite;
  display: block;
  margin: auto;
}

::placeholder {
  color: #cbd5e0 !important; 
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.datepicker-blue {
  width: 100%;

  .calendar-container {
    background: #32A8E4;
    border: 1px solid #32A8E4;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .3);
    top: 35px;
    left: 0;
    font-weight: 700;
  }

  .month-year-text {
    color: #ffffff;
  }

  .control {
    path {
      fill: #eff1f5;
    }

    &:hover {
      path {
        fill: #ffffff;
      }
    }
  }

  .day-name-unit {
    color: #fafafa;
  }

  .day-unit,
  .year-unit {
    color: #ffffff;

    &.is-prev-month {
      color: #8ed0f0;
    }

    &.is-today {
      background: #8ed0f0;
    }

    &:hover,
    &.is-selected {
      background: #ffffff;
      color: #686669;
    }

    &.is-disabled {
      color: #aaa8ab;

      &:hover {
        background: transparent;
      }
    }
  } 
}